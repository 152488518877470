<template>
  <div>
    <div class="text-center">
      This is a proof of blocks sequence. Each block position is hashed with SHA-256 algorithm. A combined string is obtained by concatenating SHA-256 of each block in this sequence. The final proof is obtained by SHA-256 hashing this combined string. This is the final provenance
      record stored on the smart contract.
      <h4>Final Proof Hash: {{ finalHash }}</h4>
    </div>
    <div v-for="tier in 6" :key="tier">
      <h3>Tier {{ tier }}</h3>
      <div v-for="(block, index) in tierList(tier)" :key="index">Token ID: {{ index }} | {{ hash(block.x + "x" + block.y) }} | Position: {{ block.x }}x{{ block.y }} <span class="box" :style="{ backgroundColor: colors[block.rarity] }"></span></div>
    </div>
  </div>
</template>

<script>
import blocksMeta from "../../data/data-tiers.json";
export default {
  data() {
    return {
      colors: {
        common: "#8eff97",
        uncommon: "#a7f1fe",
        rare: "#1edaff",
        epic: "#e569fb",
        legendary: "#fad649",
        satoshi: "#fd8918",
        unknown: "#dddddd"
      },
      finalHash: null
    };
  },
  created() {},
  methods: {
    tierList(tier) {
      return blocksMeta[tier].filter(row => row.tier == tier);
    },
    hash(data) {
      return window.crypto.subtle.digest("SHA-256", data);
    }
  }
};
</script>

<style>
.box {
  display: inline-block;
  width: 15px;
  height: 15px;
}
</style>
